/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/86d6d32ab6c2e21d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/07304b90e9b5ca40-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/54f3953b28e50f98-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/a40ccebe730e6b34-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/01ec6e01ff4830ae-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/34d8d58b783ee6bb-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/3763ada0a9bb1988-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/787b0654beea4191-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/2c3c1f0838a71cfb-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/47bcda1e7b1ac8be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/bed76354f405346d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/c70aa70a01eaecff-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/24cfcc12c15b03b9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/29ffcd3df3f1cb91-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3cef106cd115e360-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a0c95037dd5c9387-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e889cd181219a83f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6d6d0e4a023f9f33-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0148c4ca3456ea72-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4184cf5f191b83db-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28cea0425961ab58-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b4c759cba7a5f011-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/de555e6bc944c11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/791334aa8b2b679f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a2411df190893074-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/60d74363e715829c-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/eb37c9fe4776ea97-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bc381c5935034aa5-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f565fad065ab4cf3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f9946f06d70a1b5b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d1f5f89450ecd78c-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ebcf93a611f94ec0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ec50b434b597bc61-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6be22a85bef54f08-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9491f41002d34397-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/afba6ba6bf9157e8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/be843c5b0882ad66-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/67aea609c6135b3b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/09b62d668edb05d1-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/10446295ae7f2657-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b6ecfd81ed9f83c9-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Sans_9bf800';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/08404bcfb1dae67a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Sans_Fallback_9bf800';src: local("Arial");ascent-override: 101.44%;descent-override: 27.22%;line-gap-override: 0.00%;size-adjust: 101.04%
}.__className_9bf800 {font-family: '__IBM_Plex_Sans_9bf800', '__IBM_Plex_Sans_Fallback_9bf800';font-style: normal
}

